<template>
    <shopAfterPaymentCompo message="주문 및 결제가 완료되었습니다!!" />
</template>

<script>
import shopAfterPaymentCompo from "@/views/shop/AfterPayment/ShopAfterPaymentCompo.vue";
export default {
    components: {
        shopAfterPaymentCompo,
    },
};
</script>

<style></style>
