import { render, staticRenderFns } from "./PostDetailTopCompo.vue?vue&type=template&id=35c5dd70&scoped=true"
import script from "./PostDetailTopCompo.vue?vue&type=script&lang=js"
export * from "./PostDetailTopCompo.vue?vue&type=script&lang=js"
import style0 from "./PostDetailTopCompo.vue?vue&type=style&index=0&id=35c5dd70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c5dd70",
  null
  
)

export default component.exports