import { render, staticRenderFns } from "./CarouselCompo.vue?vue&type=template&id=11739384&scoped=true"
import script from "./CarouselCompo.vue?vue&type=script&lang=js"
export * from "./CarouselCompo.vue?vue&type=script&lang=js"
import style0 from "./CarouselCompo.vue?vue&type=style&index=0&id=11739384&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11739384",
  null
  
)

export default component.exports