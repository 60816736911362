import { render, staticRenderFns } from "./ConfirmModalCompo.vue?vue&type=template&id=2a5856dc&scoped=true"
import script from "./ConfirmModalCompo.vue?vue&type=script&lang=js"
export * from "./ConfirmModalCompo.vue?vue&type=script&lang=js"
import style0 from "./ConfirmModalCompo.vue?vue&type=style&index=0&id=2a5856dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a5856dc",
  null
  
)

export default component.exports