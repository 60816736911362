import { render, staticRenderFns } from "./LoginModalCompo.vue?vue&type=template&id=55c3955e&scoped=true"
import script from "./LoginModalCompo.vue?vue&type=script&lang=js"
export * from "./LoginModalCompo.vue?vue&type=script&lang=js"
import style0 from "./LoginModalCompo.vue?vue&type=style&index=0&id=55c3955e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c3955e",
  null
  
)

export default component.exports