import { render, staticRenderFns } from "./PointCompo.vue?vue&type=template&id=400ab788&scoped=true"
import script from "./PointCompo.vue?vue&type=script&lang=js"
export * from "./PointCompo.vue?vue&type=script&lang=js"
import style0 from "./PointCompo.vue?vue&type=style&index=0&id=400ab788&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "400ab788",
  null
  
)

export default component.exports