<template>
    <div>
        <!-- <h1>Walk Compo</h1> -->
        <router-view />
    </div>
</template>

<script>
export default {
    name: "WalkCompo",
};
</script>
