<template>
    <div>
        <h1>shop test compo</h1>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
