import { render, staticRenderFns } from "./QuestCompo.vue?vue&type=template&id=08f32abc&scoped=true"
import script from "./QuestCompo.vue?vue&type=script&lang=js"
export * from "./QuestCompo.vue?vue&type=script&lang=js"
import style0 from "./QuestCompo.vue?vue&type=style&index=0&id=08f32abc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f32abc",
  null
  
)

export default component.exports