import { render, staticRenderFns } from "./CommentTopCompo.vue?vue&type=template&id=1b6b9182&scoped=true"
import script from "./CommentTopCompo.vue?vue&type=script&lang=js"
export * from "./CommentTopCompo.vue?vue&type=script&lang=js"
import style0 from "./CommentTopCompo.vue?vue&type=style&index=0&id=1b6b9182&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b6b9182",
  null
  
)

export default component.exports