import { render, staticRenderFns } from "./PageNotFoundCompo.vue?vue&type=template&id=642e2ce0&scoped=true"
import script from "./PageNotFoundCompo.vue?vue&type=script&lang=js"
export * from "./PageNotFoundCompo.vue?vue&type=script&lang=js"
import style0 from "./PageNotFoundCompo.vue?vue&type=style&index=0&id=642e2ce0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642e2ce0",
  null
  
)

export default component.exports